.input-container {
  display: flex;
  flex-direction: column;
}
.text-label {
  color: var(--azul-gac);
  font-weight: 700;
  margin: 10px 0;
}
.custom-select {
  height: 40px;
  width: max-content;
  border-radius: 10px;
  padding: 2px 2em;
  font-size: 16px;
  border: none;
  box-shadow: 0px 0px 10px #5f89c8;
  color: #a7a7a7;
  font-weight: 700;
}
.custom-textArea {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 10px #5f89c8;
  color: #a7a7a7;
  font-weight: 700;
}
.btn-ticket {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  font-weight: 800;
  border-radius: 10px;
  padding: 6px 20px;
  border: none;
  margin: 10px 0;
  justify-content: end;
}
.validateInput {
  color: var(--rojo-gac);
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}
.focusError {
  border: 2px solid var(--rojo-gac);
  border-radius: 10px;
}
.focusError:not(:focus):invalid {
  border: 2px solid var(--rojo-gac);
  border-radius: 10px;
}
.container-tickets {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(271px, 1fr));
  margin: 1em 0;
}

.ticket-standar {
  box-shadow: 0 0 10px rgba(0, 56, 147, 0.49);
  border-radius: 0.4em;
  height: 110px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 1em;
}
.iconTicket {
  height: 100%;
  width: 80%;
}
.circleIcon {
  border: 2px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  padding: 5px;
}
.textTickets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  letter-spacing: -0.05em;
  font-weight: bold;
  text-align: end;
  text-transform: uppercase;
}
.textTickets h5 {
  color: var(--azul-gac);
  font-weight: 900;
  margin: 0;
}
.textTickets span {
  font-size: calc(50px + (63 - 50) * ((100vw - 320px) / (1920 - 320)));
  margin: -15px 0 0 0;
}
