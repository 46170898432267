.loginContainer {
  background: url("/public/img/LogIn.png");
  min-height: 118vh;
  background-size: contain;
}
.rowLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10% auto;
}
.shapeLogin {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  width: 543px;
  height: 200px;
  border-radius: 0 9em 9em 0;
  padding: 2em;
}
.circleLogin {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  height: 230px;
  width: 230px;
  border-radius: 50%;
  position: absolute;
  margin: 0 0 0 -543px;
  z-index: 1;
}
.circleLogin img {
  width: 70%;
  height: 70%;
  margin: 0 auto;
  border-radius: 50%;
  padding: 0em;
  margin: 2em;
  box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.1), 24px 0px 10px rgba(0, 0, 0, 0.1);
}
.contentForm {
  background: var(--azul-gac);
  border-radius: 0 9em 9em 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 0 6%;
  padding: 1em 1em 1em 4em;
}
.CustomLoginInput {
  width: 100%;
  height: 40px;
  background: #a1b0cc;
  border: none;
  padding: 0 1em;
  color: var(--fondo-defecto);
  font-weight: 600;
  margin: 5px 0;
}
.CustomLoginInput::placeholder {
  color: var(--fondo-defecto);
  opacity: 0.6;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.CustomLoginInput:active {
  border: none;
}
.customRadio {
  text-align: center;
  padding: 1em 0 0 0;
  color: #fff;
  opacity: 0.6;
}
.btn-login-v2 {
  border: none;
  background: transparent;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.6;
  font-weight: 900;
  font-size: 20px;
  margin: 16px;
}
.errorForm {
  background: rgba(255, 255, 255, 0.8);
  padding: 0.8em 1em;
  border-radius: 0.5em;
  margin: 0.6em 0 0 0;
  color: var(--azul-gac);
  border-left: 3px solid var(--azul-gac);
  box-shadow: 5px 5px var(--azul-gac);
  animation: scale-up-center 0.4s;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
/* Responsive */
@media only screen and (max-width: 500px) {
  .loginContainer {
    min-height: 100vh;
    background-size: cover;
  }
  .circleLogin {
    background: rgba(255, 255, 255, 0.4);
    height: 170px;
    width: 170px;
    margin: -226px 0 0 0;
  }
  .circleLogin img {
    width: 80%;
    height: 80%;
    margin: 1.1em;
  }
  .rowLogin {
    margin: 30% auto;
  }
  .shapeLogin {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 150px;
    border-radius: 7em;
    padding: 1em;
    margin: 60px 0 0 0;
  }
  .contentForm {
    border-radius: 5em;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0.5em 3em;
  }
  #sectionAction {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }
}
