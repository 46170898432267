.card-redemptions {
  align-items: center;
  border-radius: 1rem;
  background: #fff;
  width: 32.4%;
  min-height: 180px;
  display: flex;
  justify-content: left;
  margin: 0.3rem;
  padding: 0.6rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  box-shadow: 0 16px 24px 2px rgba(29, 17, 51, 0.04),
    0 6px 32px 4px rgba(9, 32, 77, 0.12), 0 8px 12px -5px rgba(29, 17, 51, 0.12);
}
.card-redemptions .img {
  width: 80px;
  height: 80px;
  margin-left: 1px;
  border-radius: 50%;
}

.card-redemptions:hover > .img {
  transition: 0.3s ease-in-out;
}

.card-redemptions:hover {
  transform: scale(1.03);
}

.card-redemptions .textBox {
  width: calc(100% - 90px);
  color: var(--oscuro);
  font-weight: 400;
  font-family: "Poppins" sans-serif;
  max-height: 120px;
  overflow-y: auto;
}

.card-redemptions .textContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-redemptions .span {
  font-size: 10px;
  color: var(--oscuro);
}

.card-redemptions .h1 {
  font-size: 16px;
  font-weight: 500;
}

.title {
  font-size: max(14px, min(2vw, 16px));
  font-weight: 700;
  border-bottom: 2px solid var(--azul-gac);
  color: var(--azul-gac);
  letter-spacing: -0.05em;
}

.card-redemptions .p {
  font-size: 12px;
  margin: 0.3rem;
  font-weight: 400;
}

.task-box {
  border-radius: 12px;
  background-color: var(--azul-gac);
  color: #fff;
  cursor: pointer;
  margin: 20px 10px;
  width: max-content;
  padding: 16px;
}
.task-box:hover {
  transform: scale(1.02);
}
.estado {
  border-radius: 4px;
  background-color: #37495c;
  color: var(--fondo-defecto);
  padding: 0.1rem 0.3rem;
  width: max-content;
}
.btn-state {
  border: none;
  border-radius: 0.6em;
  background-color: var(--amarillo-gac);
  padding: 0.3em 1em;
}
.btn-state.active {
  background-color: rgb(13, 150, 98);
  color: var(--fondo-defecto);
}
.categoryTicket {
  color: var(--azul-gac);
  border: none;
  background: var(--fondo-defecto);
  text-transform: uppercase;
  margin: 1% 5% 1% 0;
  font-weight: 500;
}
.categoryTicket.active {
  font-weight: 900;
}
.containerTicketView {
  background: var(--fondo-defecto);
  border-radius: 1em;
  max-width: 800px;
  min-width: 100%;
  height: max-content;
  color: var(--azul-gac);
  padding: 2em 2em;
  margin: 20% 0;
}
.headerTicketView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}
.textMainTicketView {
  font-weight: 700;
  margin: 0;
}
.textValueTicketView {
  color: var(--gris-gac);
}
.table-wrapper {
  overflow-x: auto;
}

.table-history {
  width: 100%;
  border-collapse: collapse;
  color: var(--azul-gac);
  font-size: 14px;
}
th,
td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #fff;
}

.stateTicket {
  border-radius: 1em;
  padding: 2px 12px;
  font-weight: 700;
  color: var(--fondo-defecto);
  /* font-size: calc(9px + (16 - 9) * ((100vw - 320px) / (1920 - 320))) */
}

.stateProgress {
  color: #e8bf13;
  box-shadow: 0 0 7px #e8bf13;
}
.stateFinish {
  box-shadow: 0 0 7px #006837;
  color: #006837;
}
.statePending {
  box-shadow: 0 0 7px #c12f2f;
  color: #c12f2f;
}
.stateProgress-support {
  background: #e8bf13;
  box-shadow: 0 0 7px #e8bf13;
}
.stateFinish-support {
  box-shadow: 0 0 7px #006837;
  background: #006837;
}
.statePending-support {
  box-shadow: 0 0 7px #c12f2f;
  background: #c12f2f;
}
/* optiones table history tickets */
.sectionOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.iconOption {
  background: var(--azul-gac);
  width: 30px;
  height: 30px;
  padding: 5px;
}
/* Estilo del checkbox */
.mi-checkbox {
  display: none;
}
.checkbox-label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}
.checkbox-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid var(--azul-gac);
  background-color: var(--fondo-defecto);
}
.mi-checkbox:checked + .checkbox-label::before {
  background-color: var(--azul-gac);
}
.mi-checkbox:checked + .checkbox-label::before {
  content: "\2713"; /* Código unicode del tick ✓ */
  text-align: center;
  line-height: 20px;
  color: var(--fondo-defecto);
}

.selectSupport {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--azul-gac);
  color: var(--azul-gac);
  margin: 1em 0;
}
.textAreaSupport {
  border-radius: 5px;
  border: 1px solid var(--azul-gac);
  color: var(--azul-gac);
  width: 100%;
  margin: 1em 0;
  padding: 1em;
}
