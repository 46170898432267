.grid-layout {
  display: grid;
  grid-template-areas:
    "Header Header Header"
    "aside main main"
    "footer footer footer";
  grid-template-columns: auto 1fr; /* El aside ocupará su tamaño contenido y el main el resto del espacio disponible */
}
.header {
  grid-area: Header;
}
.menu {
  grid-area: aside;
}
.content {
  grid-area: main;
}
.footer {
  grid-area: footer;
}
@media screen and (max-width: 500px) {
  .grid-layout {
    grid-template-areas:
      "Header Header Header"
      "main main main"
      "footer footer footer";
  }
  .menu {
    display: none;
  }
}
