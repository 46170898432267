.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px auto;
}

.tab {
  cursor: pointer;
  padding: 3px 30px;
  border-radius: 1em;
  transition: background-color 0.3s ease;
  background-color: var(--fondo-defecto);
  font-weight: bold;
  color: #5f89c8;
  box-shadow: 0px 0px 10px #5f89c8;
}

.tab.active {
  color: var(--azul-gac);
  font-weight: bold;
  box-shadow: 0px 0px 10px var(--azul-gac);
}
.tab-select {
  word-wrap: normal;
  color: #5f89c8;
  border: 1px solid #5f89c8;
  border-radius: 0.4em;
  height: 40px;
  margin: 20px auto;
  box-shadow: 0px 0px 10px #5f89c8;
  display: none;
  font-weight: 800;
  padding: 0 1em;
}
.sectionNewsGac {
  display: grid;
  grid-gap: 15px;
  width: 90%;
  margin: 0 5% 5% 5%;
  grid-template-areas:
    "uno dos tres"
    "uno dos cuatro"
    "uno dos cuatro"
    "uno cinco seis";
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-template-rows: repeat(4, 150px);
  cursor: pointer;
}
.sectionNewsGac img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
  object-position: top;
}
.section1 {
  grid-area: uno;
}
.section2 {
  grid-area: dos;
}
.section3 {
  grid-area: tres;
}
.section4 {
  grid-area: cuatro;
}
.section5 {
  grid-area: cinco;
}
.section6 {
  grid-area: seis;
}
.image-container {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 56 147 / 65%);
  border-radius: 1em;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay p {
  color: white;
  font-size: 15px;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}
@media screen and (max-width: 1000px) {
  .tab-container {
    flex-direction: column;
  }
  .sectionNewsGac {
    width: 95%;
    margin: 0 2%;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-areas:
      "uno dos tres"
      "uno dos cuatro"
      "uno cinco cuatro"
      "siete cinco seis";
    grid-gap: 6px;
  }
  .section7 {
    grid-area: siete;
  }
  .tab-container {
    display: none;
  }
  .tab-select {
    display: block;
  }
}
