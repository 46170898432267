.container-banners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2em;
}
.form-banner {
  background: #fff;
  border-radius: 1rem;
  padding: 2em;
  width: fit-content;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
}

.form-banner label {
  display: block;
  color: #252525;
}
.form-banner .group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.form-banner .error {
  background: var(--rojo-gac);
  width: fit-content;
  color: white;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 0 0.4em;
  font-weight: 600;
  margin: 0 auto;
  animation: scale-up-center 0.4s;
}
.input-error {
  border: 2px dashed var(--rojo-gac);
  padding: 0.4em;
  animation: shake-horizontal 0.8s linear both;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.btn-create-banner {
  background: var(--azul-gac);
  color: var(--bs-body-bg);
  border-radius: 5px;
  padding: 0.4em 1em;
  border: none;
  width: 100%;
  margin: 0.8em 0 0 0;
}
