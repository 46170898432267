:root {
  --azul-gac: #003893;
  --amarillo-gac: #ffcf04;
  --rojo-gac: #ed0a26;
  --gris-gac: #666;
  --oscuro: #252525;

  --fondo-defecto: #fff;
  --surface-color: #fff;
  --curve: 20;
}

ol,
ul {
  padding: 0;
}

hr {
  width: 60%;
  margin: auto;
  color: var(--azul-gac);
}
svg {
  fill: currentColor;
}
p {
  margin: 0;
}
.centrar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formEdit {
  background: var(--fondo-defecto);
  padding: 1em 2em;
  border-radius: 1em;
  color: var(--azul-gac);
  font-weight: 500;
  margin: 1em 0;
}
.btn-login {
  background-color: var(--azul-gac);
  border: none;
  border-radius: 8px;
  color: var(--fondo-defecto);
  font-weight: 500;
  width: 100%;
  padding: 12px 16px;
}
.btn-login:disabled {
  background-color: var(--gris-gac);
  color: var(--fondo-defecto);
}
.btn-login:hover {
  background-color: var(--amarillo-gac);
  color: var(--azul-gac);
}
.text-title {
  color: var(--azul-gac);
  text-align: center;
  margin: 1rem;
  font-weight: 700;
  letter-spacing: -0.05em;
}
.card-notice {
  border-radius: 17px;
  border: none;
  height: fit-content;
  overflow-y: scroll;
}
.card-notice::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
.card-notice::-webkit-scrollbar-track {
  background-color: var(--fondo-defecto);
  border-radius: 10px;
}
.card-notice::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--oscuro);
}

.section-news {
  height: max-content;
}

.fixed-title {
  position: fixed;
  z-index: 20;
  background-color: white;
  height: 13vh;
  width: 100%;
  padding: 1rem;
}
.margin {
  margin-top: 20vh;
}
.body-card-open {
  height: max-content;
  text-align: justify;
  padding: 20px;
}

.button {
  position: relative;
  display: flex;
  margin: auto;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button:disabled {
  opacity: 0;
}
.button.learn-more {
  width: 12rem;
  height: auto;
}

.button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: var(--azul-gac);
  border-radius: 1.625rem;
}

.button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: var(--azul-gac);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.button:hover .circle {
  width: 100%;
}

.button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.button:hover .button-text {
  color: #fff;
}

.button-back {
  position: relative;
  display: flex;
  margin: auto;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.button-back:disabled {
  opacity: 0;
}
.button-back.learn-more {
  width: 12rem;
  height: auto;
}

.button-back.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: var(--rojo-gac);
  border-radius: 1.625rem;
}

.button-back.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.button-back.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button-back.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(228deg);
}

.button-back.learn-more .button-back-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: var(--rojo-gac);
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.img-table {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}
.button-back:hover .circle {
  width: 100%;
}
.button-back:hover .button-back-text {
  color: #fff;
}
.border-20 {
  border-radius: 1rem;
}
.text-title2 {
  color: var(--azul-gac);
}
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--fondo-defecto);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--azul-gac);
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
  border-bottom: 1px solid #252525;
}
.modal-content {
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  border-radius: 1rem;
  padding: 0;
}

.carousel-control-next-icon {
  background-color: var(--amarillo-gac);
  padding: 2em;
  border-radius: 50%;
  background-image: url(https://www.svgrepo.com/show/510166/right-chevron.svg);
  z-index: 1;
}
.carousel-control-prev-icon {
  background-color: var(--amarillo-gac);
  padding: 2em;
  border-radius: 50%;
  background-image: url(https://www.svgrepo.com/show/510166/right-chevron.svg);
  transform: rotate(180deg);
}
.notification {
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  background: #353535;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: left;
  transition: 0.5s ease-in-out;
  padding: 0.7em 1em;
}
.notification:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.notification-img {
  width: 100px;
  height: 100px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: linear-gradient(#d7cfcf, #9198e5);
  background: url("/public/gac-notification.png") center/ cover;
}
.container-notification {
  width: calc(100% - 100px);
  margin-left: 10px;
  color: white;
  font-family: "Poppins" sans-serif;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.span-new {
  font-size: 10px;
  background: #88ffd1;
  color: #353535;
  padding: 0.3em;
  border-radius: 0.5em;
  font-weight: 500;
}
.title-notificacion {
  font-size: 16px;
  font-weight: bold;
}
.body-notificacion {
  font-size: 12px;
  font-weight: lighter;
}

@media only screen and (max-width: 428px) {
  #carouselExampleIndicators {
    margin: 10px !important;
  }
  .sidebar.close {
    width: 50px;
  }
  .sidebar {
    padding: 15px 2px;
  }
  .sidebar header .image {
    display: flex;
  }
  .sidebar header .image img {
    width: 35px;
    margin-right: 14px;
  }
  .sidebar li {
    height: 40px;
  }
  .sidebar .icon {
    min-width: 45px;
  }
  .sidebar.close ~ .home {
    left: 50px;
    min-height: 100vh;
    height: max-content;
    width: calc(100% - 50px);
  }
  .menu-bar .bottom-content .toggle-switch {
    justify-content: end;
  }
  .toggle-switch .switch {
    height: 26px;
    width: 46px;
  }
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(252px, 1fr));
  }

  .border-20 {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1580px) {
  .border-20 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
  }
}
