.aside a {
  color: var(--fondo-defecto);
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
}

.aside {
  width: 50px;
  height: fit-content;
  background-color: var(--azul-gac);
  border-radius: 0 1em 1em 0;
  padding: 20px 0;
  transition: width 0.1s ease;
  margin: 100px 0;
  position: relative;
  color: var(--fondo-defecto);
  font-weight: bold;
  position: sticky;
  top: 200px;
}
.aside:hover {
  width: 240px;
}
.aside:hover .aside-option .option-title span {
  display: inline;
}
.aside:hover .option-title span {
  opacity: 1;
  transition: 0.5s ease-in-out;
  text-transform: uppercase;
}
.circleCustom {
  position: absolute;
  float: left;
  width: 40px;
  height: 40px;
  shape-outside: circle(50% at 30%);
  clip-path: circle(50% at 0%);
  background: var(--fondo-defecto);
}
.circleCustom.top {
  transform: rotate(117deg);
  top: -7px;
  right: -11px;
}
.circleCustom.bottom {
  transform: rotate(224deg);
  bottom: -8px;
  right: -11px;
}
.aside-option {
  width: 50px;
  margin: 10px 0;
  padding: 0px 10px;
  cursor: pointer;
  min-height: 40px;
  display: grid;
  align-items: center;
}
.aside-option .icon {
  width: 30px;
  max-height: 32px;
  height: fit-content;
  margin: 2px 0;
  text-align: end;
}
.option-title:hover a {
  border-left: 3px solid;
}
.aside:hover .option-title {
  width: 100%;
}
.aside:hover .aside-option {
  width: 240px;
}

.option-title span {
  margin-left: 10px;
  display: none;
  opacity: 0;
  width: 170px;
}

.aside-option:hover {
  background: var(--fondo-defecto);
  color: var(--azul-gac);
  width: 200px;
}
.aside-option:hover .option-title {
  background: var(--fondo-defecto);
  color: var(--azul-gac);
}
.aside-option:hover .sub-options .option-title span {
  color: var(--azul-gac);
}
.aside-option .sub-options .option-title:hover span {
  border-left: 3px solid;
  padding: 0 5px;
  transition: 0.1s ease-in-out;
}
.sub-options {
  list-style-type: none;
  display: none;
  text-transform: uppercase;
}
.sub-options a {
  color: var(--fondo-defecto);
  text-decoration: none;
  height: 30px;
}
.aside:hover .sub-options {
  margin-left: 10px;
  padding: 0 10px;
}
.aside-option .sub-options {
  display: block;
}
.sub-options li {
  margin-bottom: 5px;
}
.activeOption {
  background: var(--fondo-defecto);
  color: var(--azul-gac) !important;
  width: 50px;
  height: 40px;
  position: relative;
  left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aside:hover .activeOption {
  width: 240px;
}
/* MOBIL */
.aside-mobil {
  background-color: var(--fondo-defecto);
  border-radius: 1em 0 0 1em;
  padding: 2em 0;
}
.aside-option-mobil {
  background: var(--fondo-defecto);
  min-width: 200px;
}
.option-mobil-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--azul-gac);
  text-decoration: none;
  font-weight: bold;
  padding: 0.4em 0.9em;
}
.active-mobil-option {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
}
.icon-mobil {
  width: 30px;
  height: 35px;
}
.link-mobil {
  color: var(--azul-gac);
  text-decoration: none;
  border-left: 2px solid;
  padding: 0 1em;
}
/*  */
@media only screen and (max-width: 500px) {
  .aside {
    display: none;
  }
}
@media only screen and (min-width: 500px) {
  .aside-mobil {
    display: none;
  }
}
