.sectionPagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  margin: 5% 0 0 0;
}
.btnPagination {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  padding: 1px 5px;
  border: none;
  height: 30px;
}
.btnPagination:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.page {
  color: #8090af;
  border: none;
  background: var(--fondo-defecto);
  width: 30px;
  height: 30px;
  font-weight: 500;
}
.page.active {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  border-radius: 50%;
  font-weight: 900;
}
