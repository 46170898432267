.container-news {
  max-width: 800px;
  margin: 0 auto;
  padding: 2em 0;
  text-align: justify;
}

.title-new {
  font-size: 3rem;
  font-weight: 700;
}

.back-button {
  color: var(--azul-gac);
  height: 40px;
  display: flex;
  justify-content: flex-start;
}

.back-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back-button .icon {
  height: 30px;
  margin-right: 0.5rem;
}

.date {
  color: var(--azul-gac);
  background: var(--amarillo-gac);
  border-radius: 0.7em;
  padding: 0px 2em;
  margin: 10px;
  font-weight: 500;
}

.article-container {
  display: grid;
  gap: 16px;
}

.article-content p:first-of-type {
  text-align: justify;
  grid-column: 1 / -1; /* Full width */
}

.article-content .two-column {
  display: flex;
  gap: 16px;
  align-items: flex-start; /* Ensure items are aligned to the start */
  max-width: 100%;
}

.article-content .two-column img {
  flex: 2; /* Take up 2/3 of the space */
  width: 100%;
  max-width: 80%; /* Ensure image does not exceed container */
}

.article-content .two-column p {
  flex: 1; /* Take up 1/3 of the space */
  max-width: 100%; /* Ensure paragraph does not exceed container */
}

.article-content img:nth-of-type(2) {
  grid-column: 1 / -1; /* Full width */
  width: 100%; /* Ensure it takes full width */
}

.article-content p:last-of-type {
  text-align: left;
  grid-column: 1 / -1; /* Full width */
}

/* Ensure all paragraphs and images are within the grid */
.article-content > * {
  margin: 0;
  padding: 8px;
}

.article-content {
  display: contents; /* Allow direct child elements to participate in the grid */
}
