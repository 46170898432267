.title-section-promotions {
  color: var(--azul-gac);
  font-weight: 900;
}

.files-rrhh {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 10px;
  position: relative;
}
.info-rrhh {
  background: url("/public/img/infoRRHH.png") center / contain;
  background-repeat: no-repeat;
  position: relative;
  height: 340px;
  width: 100%;
}
.title-info-rrhh {
  position: absolute;
  top: 20px;
  left: 40px;
  text-transform: uppercase;
  color: var(--amarillo-gac);
  font-weight: bold;
}
.body-info-rrhh {
  position: absolute;
  top: 80px;
  left: 40px;
  color: var(--fondo-defecto);
}
.body-info-rrhh li {
  cursor: pointer;
}
.body-info-rrhh a {
  color: var(--fondo-defecto);
  text-decoration: none;
}
.documents-rrhh {
  background: url("/public/img/documentsRRHH.png") center / contain;
  background-repeat: no-repeat;
  position: relative;
  height: 340px;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .files-rrhh {
    grid-template-columns: 1fr;
  }
  .info-rrhh {
    height: fit-content;
    min-height: 213px;
  }
  .title-info-rrhh {
    top: 20px;
    font-size: 12px;
  }
  .body-info-rrhh {
    top: 50px;
    font-size: 11px;
  }
  .documents-rrhh {
    min-height: 213px;
    height: fit-content;
  }
}
