.footerGAC {
  background-color: var(--azul-gac);
  color: #fff;
  padding: 1em;
  text-align: center;
}
.footer-text {
  font-size: 14px;
}

.rnt-tex {
  text-align: center;
  font-size: 13px;
  margin: 1em;
}
