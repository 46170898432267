/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  /* ===== Colors ===== */
  --body-color: #ececec;
  --sidebar-color: #fff;
  --primary-color: #003893;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #666;
  --azul-gac: #003893;
  --amarillo-gac: rgb(255, 207, 4);
  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

body {
  min-height: 100vh;
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

body.dark {
  --body-color: #2254a3;
  --sidebar-color: #102950;
  --primary-color: #2254a3;
  --primary-color-light: #2254a3;
  --toggle-color: #fff;
  --text-color: #ccc;
}

body.dark .sidebar header .toggle {
  background: yellow;
  color: #102950 !important;
}
body.dark td {
  /* background: #ccc;   */
  color: white;
  font-weight: 500;
}

body.dark tr:hover {
  background-color: #fff;
}

body.dark .text-title {
  color: #fff;
}
body.dark hr {
  color: #fff;
}
body.dark .button.learn-more .button-text {
  color: white;
}
body.dark .button-back.learn-more .button-back-text {
  color: white;
}

body.dark .sidebar {
  box-shadow: 0px 0px 0px #ececec;
}

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 100;
  height: 100%;
  box-shadow: 2px 0px 15px #e0dfdf;
}
.sidebar.close {
  width: 88px;
}
.sidebar.none {
  display: none;
  background: red;
  width: 0px;
  height: 0px;
  position: absolute;
}
.active-notificacion {
  background: var(--amarillo-gac);
  color: var(--azul-gac);
  width: 100%;
  border-radius: 6px;
  display: flex;
  border: none;
  height: 50px;
  justify-content: center;
  align-items: center;
}
/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}

.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.sidebar .icon-notification {
  min-width: 60px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--azul-gac);
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar .text {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}
header .image-text .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 30px;
  width: 30px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
  color: var(--text-color);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
  margin-top: 40px;
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}
.sidebar li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}

.sidebar li a:hover {
  background-color: var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
  color: var(--text-color);
}
.sidebar .active {
  background-color: var(--primary-color);
}
.sidebar .active-vicidal {
  background-color: var(--primary-color);
}
.sidebar .active-vicidal .icon:nth-child(3) {
  transform: rotate(90deg);
}
.sidebar .active-vicidal .text {
  color: #fff;
}
.sidebar .active-vicidal .icon {
  color: #fff;
}
.submenu li {
  background-color: transparent;
  border-radius: 6px;
  border: 1px dashed var(--text-color);
  animation: scale-up-center 0.4s;
}
/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.scale-up-center {
  animation: scale-up-center 0.4s;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.sidebar .active .text {
  color: #fff;
}
.sidebar .active .icon {
  color: #fff;
}
.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}

.mode .sun-moon i {
  position: absolute;
}
.mode .sun-moon i.sun {
  opacity: 0;
}
body.dark .mode .sun-moon i.sun {
  opacity: 1;
}
body.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

body.dark .switch::before {
  left: 20px;
}

.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 260px;
  height: max-content;
  min-height: 100vh;
  width: calc(100% - 260px);
  transition: var(--tran-05);
  background-color: var(--body-color);
  /* background-color: transparent; */
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.001),
      rgba(255, 255, 255, 0.001)
    ),
    url("/public/texture/Textura.svg");
}
.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .home {
  left: 78px;
  min-height: 100vh;
  height: max-content;
  width: calc(100% - 78px);
}
body.dark .home .text {
  color: var(--text-color);
}

body.dark .home {
}
