.formCreate {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.062);
  padding: 3em;
  border-radius: 2em;
  max-width: 80%;
  margin: 0 5%;
}
.formCreate .group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px 0;
}

.formCreate .group input,
select {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.formCreate .group input:focus {
  outline: none;
  border: 1px solid var(--azul-gac);
}
.group-select {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
/* DESDE aca */

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  position: absolute;
  right: 0.5rem;
  font-size: 1.5rem;
}

.valid-icon {
  color: var(--azul-gac);
  height: 100%;
  width: 30px;
  margin-right: 10px;
}

.invalid-icon {
  color: var(--rojo-gac);
  height: 100%;
  width: 30px;
}

.invalid {
  border-color: var(--rojo-gac) !important;
}

.valid {
  border-color: var(--azul-gac);
}

.error-message {
  color: var(--rojo-gac);
  font-size: 0.875rem;
}

.custum-file-upload {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 0px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
  margin: 1em 0;
}

.custum-file-upload .icon_upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon_upload svg {
  width: 80px;
  height: 80px;
  fill: rgba(75, 85, 99, 1);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
  font-size: 20px;
}

.custum-file-upload input {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}
