.navbarIntranet {
  display: flex;
  flex-direction: column;
}

.navbar-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
}

.navbar-top {
  background-color: var(--fondo-defecto);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 2;
}

.navbar-middle {
  background-color: #003893;
  color: #fff;
  margin: 80px 0 10px 0;
}

.navbar-bottom {
  background-color: rgba(0, 56, 147, 0.79);
  color: #fff;
  padding: 0 1em;
}

.logo {
  margin-left: 10px;
}

.navbar-options {
  display: flex;
}

.navbar-option {
  margin-right: 10px;
  cursor: pointer;
}
.extend {
  background: rgb(70 97 166);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  padding: 0.7em 0 0.5em 0;
  width: 210px;
  height: fit-content;
  position: absolute;
  z-index: 1;
}
.message-box,
.notification-box {
  position: absolute;
  background-color: var(--azul-gac);
  border: 1px solid #ccc;
  padding: 5px;
  width: 150px;
  z-index: 1;
  color: var(--bs-body-bg);
  border-radius: 0.5em;
  min-height: 100px;
}

.mobil-menu {
  position: absolute;
  top: 126px;
  right: 0;
  color: var(--azul-gac);
  width: fit-content;
  z-index: 2;
  min-height: 100px;
}
.user-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: rgb(0 56 147 / 79%);
  width: 210px;
  height: 60px;
  padding: 0.5em;
  border-radius: 0 1em 0 0;
  color: var(--fondo-defecto);
  gap: 10px;
}
.user-info .text-user {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 0;
}
.user-info img {
  border-radius: 50%;
  margin-right: 5px;
}

.user-name {
  font-weight: bold;
  font-size: clamp(0.75rem, 0.5875rem + 0.7429vw, 1.5625rem);
}

.user-details {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: center;
  height: 130px;
  font-size: clamp(0.75rem, 0.8vw, 1rem);
}
.user-details div {
  border-right: 2px solid white;
  width: 100%;
  padding: 0 1em;
}

.user-details div:last-child {
  border-right: none;
}

.user-details img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.user-details p {
  font-weight: bold;
}
.btn-close-sesion {
  background: #ff0b0b;
  color: var(--fondo-defecto);
  border-radius: 10px;
  padding: 3px 8px;
  box-shadow: 0px 0px 19px #ff0b0b;
  border: none;
}
.menu-button {
  display: none;
  background: transparent;
  border: none;
}
.menu-button.active {
  background: var(--fondo-defecto);
  color: #003893;
  padding: 0.4em 2em;
  border-radius: 0.5em 0 0 0;
  margin: -1px -10px;
}
.sgv-menu {
  height: 30px;
  width: 30px;
}
.sgv-menu.active {
  background: var(--azul-gac);
}
@media only screen and (max-width: 500px) {
  .navbar-top {
    height: fit-content;
  }
  .logo img {
    width: 150px;
    height: auto;
  }
  .navbar-option:nth-child(-n + 2) {
    display: none;
  }
  .user-info {
    grid-template-columns: 3fr 1fr;
    width: 188px;
  }
  .navbar-middle {
    margin: 70px 0 5px 0;
  }
  .user-details {
    height: fit-content;
    padding: 1.4em 0;
  }
  .user-details p {
    font-size: 10px;
  }
  .user-details img {
    width: 45px;
    height: 45px;
  }
  .user-details div {
    border-right: 1px solid;
    height: 10px;
  }
  .menu-button {
    display: block;
  }
}
