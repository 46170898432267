.tabConfig {
  color: var(--azul-gac);
  border: none;
  background: var(--fondo-defecto);
  margin: 1% 5% 1% 0;
  font-weight: 300;
}
.tabConfig.active {
  font-weight: 900;
  border-bottom: 2px solid;
}
.two-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 1em 8em;
}
.container-info-user {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  color: var(--azul-gac);
  font-weight: 700;
}
.container-elemets {
  display: grid;
  grid-template-columns: 1fr;
}
.container-elemets input {
  border: none;
  box-shadow: 0px 0px 10px #5f89c8;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
}
