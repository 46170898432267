.modalGac {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgb(0 56 147 / 65%);
  backdrop-filter: blur(2px);
  color: var(--fondo-defecto);
  display: none;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modalGac::-webkit-scrollbar {
  width: 0px;
}
.modalGac.is-open {
  display: flex;
}

.modalGac-container {
  position: relative;
  max-width: 90%;
  animation: blur-in 0.4s linear both;
}
@keyframes blur-in {
  0% {
    filter: blur(2px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}
.modalGac-container a {
  color: var(--fondo-defecto);
}

.modalGac-container a:hover {
  color: var(--amarillo-gac);
}
