.app {
  text-align: center;
}

.pdf-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.pdf-card {
  background: var(--azul-gac);
  border-radius: 1em;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr; /* Ajuste de las filas */
  color: var(--fondo-defecto);
}

.pdf-card h2 {
  margin-bottom: 10px;
}

.pdf-card button {
  display: block;
  background-color: var(--amarillo-gac);
  color: var(--azul-gac);
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  border: none;
}
